import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      staleTime: 3 * 1000, // 3 seconds
      refetchOnWindowFocus: false,
    },
  },
});

const ReactQueryProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export { ReactQueryProvider };
