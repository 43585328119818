import { createAnalytics } from '@rogu/tracking-sdk';

import fetcher from '@/shared/fetcher';

const { trackEvent } = createAnalytics({
  appName: 'optimus',
  fetcher: (payload) => {
    return fetcher({ ...payload, disableNodeProxy: false });
  },
  clientUA: 'WEB',
  debug: false,
});

const tracker = {
  track: async (payload: {
    eventType: string;
    context: Record<string, unknown>;
  }) => {
    trackEvent(payload);
  },
};

export default tracker;
