import { Country } from 'config/country';
import nextGetConfig from 'next/config';

type Config = {
  serverRuntimeConfig: {
    port: string;
  };
  publicRuntimeConfig: {
    apiEndpoint: string;
    basePath: string;
    assetPrefix: string;
    origin: string;
    language: string;
    clientID: string;
    clientSecret: string;
    oAuthClientID: string;
    versionDigest: string;
    isProduction: boolean;
    accountUIURL: string;
    country: Country;
  };
};

function getConfig() {
  return nextGetConfig() as Config;
}

export { getConfig };
