import { configure } from '@rogu/fetch';
import { getConfig } from 'shared/getEnv';

const {
  publicRuntimeConfig: { country, apiEndpoint, basePath },
} = getConfig();

const fetcher = configure({
  headers: {
    country: country,
  },
  server: {
    host: apiEndpoint,
  },
  client: {
    host: apiEndpoint,
    disableNodeProxy: false,
    basePath: basePath,
    onRefreshTokenFailed: () => {
      const logoutUrl =
        '/logout?redirect_url=' + encodeURIComponent(window.location.href);
      // Clear old Token
      fetcher({
        url: logoutUrl,
        method: 'get',
        withAuth: true,
      })
        .then(() => {
          // Set new guest Token
          fetch(window.location.href).catch(() => window.location.reload());
        })
        .catch(() => {
          window.location.href = logoutUrl;
        });
    },
  },
});

export default fetcher;
