import 'core-js/features/object/from-entries';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import Head from 'next/head';
import { ReactQueryProvider } from 'uikit/layout/ReactQueryProvider';
import { browserCookie } from 'shared/cookie';
import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import NextNProgress from 'nextjs-progressbar';
import '../modules/tracking/trackingService';
function MyApp({ Component, pageProps }: AppProps) {
  const isLoggedIn = browserCookie.get('isLoggedIn');
  const router = useRouter();

  useEffect(() => {
    if (!isLoggedIn) {
      router.push(`/landing`);
    }
  }, [router.pathname, isLoggedIn]);

  return (
    <ChakraProvider>
      <NextNProgress />

      <ReactQueryProvider>
        <Head>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Component {...pageProps} />
      </ReactQueryProvider>
    </ChakraProvider>
  );
}

export default MyApp;
